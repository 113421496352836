<template>
	<div class="text-truncate" v-if="info">{{ info }}</div>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';

	export default {
		props: {
			users: Array
		},
		setup(props) {
			const info = computed(() => {
				let main = _.find(props.users, { pivot: { creator: (_.some(props.users, { pivot: { creator: true }})) ? true : false }}),
					others = _.filter(props.users, (user) => { return (user.id != main?.id); });

				if(main) return `${main.name}${(others.length > 0) ? ` y ${others.length} más` : ``}`;
				else return null;
			});

			return { info };
		}
	}
</script>