<template>
	<router-view v-slot="{ Component }">
		<transition name="fade-fast" mode="out-in">
			<component :is="Component" :microsite="$env?.MICROSITE" />
		</transition>
	</router-view>
</template>

<script>
	import { computed, onMounted, inject } from 'vue';
	import store from '@/store';
	import composableForm from '@/composables/form';
	import MicrositeRepository from '@/repositories/MicrositeRepository';

	export default {
		setup() {
			const { catchError } = composableForm();
			const $env = inject('$env');
			const microsite = computed(() => store.getters['microsite/info']);
			const loaded = computed(() => ($env?.MICROSITE.id == microsite.value?.id));

			onMounted(async () => {
				if(!loaded.value) {
					await store.dispatch('microsite/empty');
					await MicrositeRepository.show($env?.MICROSITE.id).then((response) => {
						store.dispatch('microsite/setInfo', response.data);
					}).catch((e) => catchError(e, true));
				}
			});

			return { microsite, loaded };
		}
	}
</script>