<template>
	<LayoutMain>
		<LayoutHeaderSection>
			<template #title>
				<LayoutTextSection text="Ver estadísticas" :breadcrumb="true" />
			</template>
			<template #actions v-if="sources.length > 1 && !!filters.source">
				<div class="hstack gap-2 justify-content-start justify-content-lg-end">
					<a href="javascript:void(0);" class="btn btn-sm btn-neutral rounded-3 lh-xs text-start hstack gap-2 ps-2" @click="modalOption?.show();">
						<i class="bi bi-database text-xl text-muted"></i>
						<div>
							<template v-if="!filters.source"><b>Seleccionar</b><br /><small>Fuente de estadísticas</small></template>
							<template v-else-if="filters.source.type == 'App\\Models\\User'"><b>{{ filters.source.entity.name }}</b><br /><small>Estadísticas de la cuenta</small></template>
							<template v-else-if="filters.source.type == 'App\\Models\\Microsite'"><b>{{ filters.source.entity.name }}</b><br /><small>Estadísticas del sitio</small></template>
							<template v-else-if="filters.source.type == 'App\\Models\\Group'"><b>{{ filters.source.entity.name }}</b><br /><small>Estadísticas del grupo</small></template>
						</div>
					</a>
				</div>
			</template>
		</LayoutHeaderSection>
		<main class="container-md pt-5 pb-10 pb-0 vstack gap-4">
			<template v-if="!!filters.source">
				<CommonFormInputDateRange v-model="filters.dates" placeholder="Seleccionar fechas" :defaultRange="$env.GENERAL.stat.range.default" :maxRange="$env.GENERAL.stat.range.max" />
				<AccountStatReportPlaceholder v-if="loading" />
				<template v-else>
					<AccountStatReportUser :report="report" v-if="filters.source.type == 'App\\Models\\User'" />
					<AccountStatReportMicrosite :report="report" v-else-if="filters.source.type == 'App\\Models\\Microsite'" />
					<AccountStatReportGroup :report="report" v-else-if="filters.source.type == 'App\\Models\\Group'" />
				</template>
			</template>
			<template v-else-if="loadingSources">
				<CommonMessageInfo info="Cargando fuentes..." />
			</template>
			<div class="vstack gap-4" v-else>
				<p class="text-muted text-sm lh-sm">
					Podrás consultar estadísticas sobre tu cuenta, sobre los grupos que administras, y más, con un rango máximo de {{ $env.GENERAL.stat.range.max }} días.<br />
					Para comenzar seleccioná la fuente de las estadísticas que deseas consultar.
				</p>
				<div>
					<button class="btn btn-sm btn-dark rounded-pill" @click="modalOption?.show();">Seleccionar fuente</button>
				</div>
			</div>
		</main>

		<AccountStatModalOption ref="modalOption" :sources="sources" @select="select" />
	</LayoutMain>
</template>

<script>
	import { ref, reactive, watch, onMounted, inject } from 'vue';
	import moment from 'moment';
	import composableForm from '@/composables/form';
	import AccountStatRepository from '@/repositories/Account/StatRepository';

	export default {
		setup() {
			const $env = inject('$env');
			const { loading, catchError } = composableForm();
			const loadingSources = ref(true);
			const modalOption = ref(null);
			const sources = ref([]);
			const report = ref(null);

			const filters = reactive({
				source: null,
				dates: [
					moment().subtract($env.GENERAL.stat.range.default, 'day'),
					moment()
				]
			});

			const select = (data) => {
				filters.source = data;
			}

			const getStat = async () => {
				if(!filters.source) return;
				loading.value = true;

				await AccountStatRepository.general(filters).then((response) => {
					report.value = response.data;
				}).catch((e) => {
					report.value = null;
					catchError(e, true);
				});

				loading.value = false;
			}

			onMounted(async () => {
				await AccountStatRepository.sources().then((response) => {
					sources.value = response.data;
					if(sources.value.length == 1) select(sources.value[0]);
				}).catch((e) => {
					sources.value = [];
					catchError(e, true);
				});

				loadingSources.value = false;
			});

			watch(filters, getStat, { deep: true });

			return { loading, loadingSources, modalOption, sources, filters, select, report };
		}
	}
</script>