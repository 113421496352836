import { createRouter, createWebHistory } from 'vue-router';
import { inject } from 'vue';
import store from '@/store';
import { Modal, Offcanvas } from 'bootstrap';
import { useToast } from 'vue-toastification';
import _ from 'lodash';

import Home from '@/views/Home.vue';
import LegalTerms from '@/views/Legal/Terms.vue';
import LegalPrivacy from '@/views/Legal/Privacy.vue';
import NotFound from '@/views/NotFound.vue';

import AuthLogout from '@/views/Auth/Logout.vue';

import AccountGroupIndex from '@/views/Account/Group/Index.vue';
import AccountGroupForm from '@/views/Account/Group/Form.vue';
import AccountGroupShow from '@/views/Account/Group/Show.vue';
import AccountGroupSearch from '@/views/Account/Group/Search.vue';
import AccountGroupPublication from '@/views/Account/Group/Publication.vue';

import AccountPublicationIndex from '@/views/Account/Publication/Index.vue';
import AccountPublicationSynchronizer from '@/views/Account/Publication/Synchronizer.vue';
import AccountPublicationForm from '@/views/Account/Publication/Form.vue';

import AccountStatIndex from '@/views/Account/Stat/Index.vue';
import AccountStatPublication from '@/views/Account/Stat/Publication.vue';

import InvitationIndex from '@/views/Invitation/Index.vue';

import AccountSettingIndex from '@/views/Account/Setting/Index.vue';
import AccountSettingSlug from '@/views/Account/Setting/Slug.vue';

import AccountSearchIndex from '@/views/Account/Search/Index.vue';
import AccountSearchFiltered from '@/views/Account/Search/Filtered.vue';

import SearchIndex from '@/views/Search/Index.vue';
import SearchResult from '@/views/Search/Result.vue';

import AdminUserIndex from '@/views/Admin/User/Index.vue';
import AdminUserForm from '@/views/Admin/User/Form.vue';

import AdminGroupIndex from '@/views/Admin/Group/Index.vue';
import AdminGroupForm from '@/views/Admin/Group/Form.vue';

import AdminMicrositeIndex from '@/views/Admin/Microsite/Index.vue';
import AdminMicrositeForm from '@/views/Admin/Microsite/Form.vue';

import AdminSearchIndex from '@/views/Admin/Search/Index.vue';

import UserLoad from '@/components/User/Load.vue';
import UserHome from '@/views/User/Home.vue';
import UserSearchIndex from '@/views/User/Search/Index.vue';

import GroupLoad from '@/components/Group/Load.vue';
import GroupSearchIndex from '@/views/Group/Search/Index.vue';

import MicrositeLoad from '@/components/Microsite/Load.vue';
import MicrositeHome from '@/views/Microsite/Home.vue';
import MicrositeSearchIndex from '@/views/Microsite/Search/Index.vue';

const toast = useToast();

const routes = [
	{
		path: '/',
		name: 'home',
		meta: { micrositeForbidden: true },
		component: Home
	},
	{
		path: '/legal',
		children: [
			{
				path: 'terms',
				name: 'legal.terms',
				component: LegalTerms
			},
			{
				path: 'privacy',
				name: 'legal.privacy',
				component: LegalPrivacy
			}
		]
	},
	{
		path: '/search',
		children: [
			{
				path: '',
				name: 'search.index',
				component: SearchIndex
			},
			{
				path: ':uuid',
				children: [
					{
						path: '',
						name: 'search.result',
						component: SearchResult
					}
				]
			}
		]
	},
	{
		path: '/auth',
		children: [
			{
				path: 'logout',
				name: 'auth.logout',
				meta: { needUser: true },
				component: AuthLogout
			},
			{
				path: 'social/:service',
				name: 'auth.social',
				meta: { needUser: false },
				component: NotFound
			}
		]
	},
	{
		path: '/account',
		meta: { needUser: true, breadcrumb: 'Cuenta' },
		children: [
			{
				path: 'setting',
				meta: { breadcrumb: 'Configuración' },
				children: [
					{
						path: '',
						name: 'account.setting.index',
						component: AccountSettingIndex
					},
					{
						path: 'slug',
						name: 'account.setting.slug',
						component: AccountSettingSlug
					}
				]
			},
			{
				path: 'search',
				meta: { breadcrumb: 'Búsquedas' },
				children: [
					{
						path: 'index',
						name: 'account.search.index',
						component: AccountSearchIndex
					},
					{
						path: ':uuid',
						children: [
							{
								path: 'filtered',
								name: 'account.search.filtered',
								component: AccountSearchFiltered
							}
						]
					}
				]
			},
			{
				path: 'group',
				children: [
					{
						path: 'index',
						name: 'account.group.index',
						meta: { breadcrumb: 'Grupos' },
						component: AccountGroupIndex
					},
					{
						path: 'create',
						name: 'account.group.create',
						meta: { breadcrumb: 'Grupos' },
						component: AccountGroupForm
					},
					{
						path: ':id',
						meta: { breadcrumb: 'Grupo' },
						children: [
							{
								path: 'edit',
								name: 'account.group.edit',
								component: AccountGroupForm
							},
							{
								path: 'show',
								name: 'account.group.show',
								component: AccountGroupShow
							},
							{
								path: 'search',
								name: 'account.group.search',
								meta: { breadcrumb: 'Búsquedas activas' },
								component: AccountGroupSearch
							},
							{
								path: 'publication',
								name: 'account.group.publication',
								meta: { breadcrumb: 'Cartera Interna' },
								component: AccountGroupPublication
							}
						]
					}
				]
			},
			{
				path: 'publication',
				meta: { breadcrumb: 'Publicaciones' },
				children: [
					{
						path: 'index',
						name: 'account.publication.index',
						component: AccountPublicationIndex
					},
					{
						path: 'synchronizer',
						name: 'account.publication.synchronizer',
						component: AccountPublicationSynchronizer
					},
					{
						path: 'create',
						name: 'account.publication.create',
						component: AccountPublicationForm
					},
					{
						path: ':id',
						children: [
							{
								path: 'edit',
								name: 'account.publication.edit',
								component: AccountPublicationForm
							}
						]
					}
				]
			},
			{
				path: 'stat',
				meta: { breadcrumb: 'Estadísticas' },
				children: [
					{
						path: 'index',
						name: 'account.stat.index',
						component: AccountStatIndex
					},
					{
						path: 'publication/:id',
						name: 'account.stat.publication',
						component: AccountStatPublication
					}
				]
			}
		]
	},
	{
		path: '/invitation',
		children: [
			{
				path: ':uuid',
				children: [
					{
						path: '',
						name: 'invitation.index',
						component: InvitationIndex
					}
				]
			}
		]
	},
	{
		path: '/admin',
		meta: { needUser: true, breadcrumb: 'Administración' },
		children: [
			{
				path: 'user',
				meta: { breadcrumb: 'Usuarios', permissions: ['users'] },
				children: [
					{
						path: 'index',
						name: 'admin.user.index',
						component: AdminUserIndex
					},
					{
						path: ':id',
						name: 'admin.user.form',
						component: AdminUserForm
					}
				]
			},
			{
				path: 'group',
				meta: { breadcrumb: 'Grupos', permissions: ['groups'] },
				children: [
					{
						path: 'index',
						name: 'admin.group.index',
						component: AdminGroupIndex
					},
					{
						path: ':id',
						name: 'admin.group.form',
						component: AdminGroupForm
					}
				]
			},
			{
				path: 'microsite',
				meta: { breadcrumb: 'Micrositios', permissions: ['microsites'] },
				children: [
					{
						path: 'index',
						name: 'admin.microsite.index',
						component: AdminMicrositeIndex
					},
					{
						path: 'create',
						name: 'account.microsite.create',
						component: AdminMicrositeForm
					},
					{
						path: ':id',
						name: 'admin.microsite.edit',
						component: AdminMicrositeForm
					}
				]
			},
			{
				path: 'search',
				meta: { breadcrumb: 'Búsquedas', permissions: ['searches'] },
				children: [
					{
						path: 'index',
						name: 'admin.search.index',
						component: AdminSearchIndex
					}
				]
			}
		]
	},
	{
		path: '/group/:id',
		component: GroupLoad,
		children: [
			{
				path: 'search',
				children: [
					{
						path: '',
						name: 'group.search.index',
						component: GroupSearchIndex
					}
				]
			}
		]
	},
	{
		path: '/microsite',
		component: MicrositeLoad,
		children: [
			{
				path: '',
				name: 'microsite.home',
				component: MicrositeHome
			},
			{
				path: 'search',
				children: [
					{
						path: '',
						name: 'microsite.search.index',
						component: MicrositeSearchIndex
					}
				]
			}
		]
	},
	{
		path: '/:slug',
		component: UserLoad,
		meta: { userSection: true },
		children: [
			{
				path: '',
				name: 'user.home',
				component: UserHome
			},
			{
				path: 'search',
				children: [
					{
						path: '',
						name: 'user.search.index',
						component: UserSearchIndex
					}
				]
			}
		]
	},
	{
		path: '/:pathMatch(.*)*',
		component: NotFound
	}
];

window.popStateDetected = false;
window.addEventListener('popstate', () => window.popStateDetected = true);

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: routes,
	scrollBehavior(to, from, savedPosition) {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(savedPosition || { left: 0, top: 0 });
			}, 300);
		});
	}
});

router.beforeEach(async (to, from) => {
	let $env = inject('$env'),
		IsItABackButton = window.popStateDetected;

	window.popStateDetected = false;

	if(document.querySelector('.vel-modal')) {
		document.querySelector('#vue-easy-lightbox-close').click();

		if(IsItABackButton) return false;
	}

	if(document.querySelector('.offcanvas.show')) {
		var offcanvasShowed = Offcanvas.getInstance(document.querySelector('.offcanvas.show'));
		offcanvasShowed.hide();

		if(IsItABackButton) return false;
	}

	if(document.querySelector('.modal.show')) {
		var modalShowed = Modal.getInstance(document.querySelector('.modal.show'));
		modalShowed.hide();

		if(IsItABackButton) return false;
	}

	if(document.querySelector('[data-v-onboarding-card]')) {
		toast.warning(`Debes cerrar la guía en pantalla.`);
		return false;
	}

	if(to.meta?.needUser === true && !store.getters['auth/hasUser']) {
		toast.error(`Necesitas estar logueado.`);
		return router.push({ name: 'home' });
	}

	if(to.meta?.needUser === false && store.getters['auth/hasUser']) {
		toast.error(`No te encuentras logueado.`);
		return router.push({ name: 'home' });
	}

	if((to.meta?.permissions ?? []).length > 0 && _.intersection(to.meta.permissions, store.getters['auth/user'].permissions).length == 0) {
		toast.error(`No tienes los permisos suficientes.`);
		return router.push({ name: 'home' });
	}

	if(to.name == 'auth.logout') {
		store.dispatch('redirect/setNext', from.fullPath);
	}

	if($env?.MICROSITE) {
		if(to.meta?.micrositeForbidden)
			return router.push({ name: 'microsite.home' });

		if(to.meta?.userSection && !$env.MICROSITE.settings.users)
			return router.push({ name: 'microsite.home' });
	}
});

export default router;
