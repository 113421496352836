<template>
	<LayoutMain>
		<main class="container-md pb-0 vstack gap-10 pb-32 pt-5 pt-lg-10">
			<div class="vstack gap-3">
				<MicrositeCardSearch :microsite="microsite" v-model:user_id="search.user_id" class="pb-2" v-if="microsite" />
				<UserCardSubtitle :user="user" subtitle="Buscar en la cuenta" class="pb-2" v-else-if="user" />
				<GroupCardSubtitle :group="group" subtitle="Buscar en el grupo" class="pb-2" v-else-if="group" />
				<AppCardSubtitle subtitle="Buscar en toda la plataforma" class="pb-2" v-else />

				<SearchPartFilters :filters="search.filters" @remove="groupRemove" v-if="search.filters.length > 0" />
				<SearchPartForm ref="form" :filters="prefilters" />
				<SearchFilterPlaces v-model="search.places" step="places" v-if="stepsGeneral.includes('places')" />
				<SearchPartOrder v-model="search.order" v-if="canSearch" />
			</div>
		</main>
		<footer class="footer mt-auto py-4 bg-white fixed-bottom border-top border-2 border-dark">
			<div class="container-md">
				<div class="hstack gap-4 align-items-center justify-content-between">
					<div class="hstack gap-2">
						<a href="javascript:void(0);" class="btn btn-sm btn-outline-dark btn-square rounded-circle text-md" @click="modalGroups.show();" v-if="groups.length > 0"><i class="bi bi-people"></i></a>
						<a href="javascript:void(0);" class="btn btn-sm btn-outline-dark rounded-pill" @click="modalSearches.show();" v-if="searches.length > 0"><i class="bi bi-clock-history pe-2"></i>Anteriores</a>
					</div>
					<div class="hstack gap-2">
						<a href="javascript:void(0);" class="btn btn-xs btn-outline-primary btn-square rounded-circle text-md" @click="groupAdd" v-if="form?.completed"><i class="bi bi-plus-lg"></i></a>
						<button type="button" class="btn btn-primary rounded-pill" :disabled="submitting || !canSearch" @click="submit"><i class="bi bi-search pe-2"></i>Buscar</button>
					</div>
				</div>
			</div>
		</footer>

		<SearchModalList ref="modalSearches" :searches="searches" />
		<SearchModalGroups ref="modalGroups" :groups="groups" />
	</LayoutMain>
</template>

<script>
	import { ref, computed } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import { useToast } from 'vue-toastification';
	import _ from 'lodash';
	import VueScrollTo from 'vue-scrollto';
	import composableForm from '@/composables/form';
	import SearchRepository from '@/repositories/SearchRepository';

	export default {
		props: {
			microsite: Object,
			user: Object,
			group: Object,
			prefilters: {
				type: Object,
				default: {}
			}
		},
		setup(props) {
			const { submitting, catchError } = composableForm();
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const user = computed(() => store.getters['auth/user']);
			const toast = useToast();
			const form = ref(null);
			const search = ref({
				uuid: null,
				microsite_id: props.microsite?.id ?? null,
				user_id: props.user?.id ?? null,
				group_id: props.group?.id ?? null,
				title: null,
				filters: [],
				order: 'created-desc',
				places: []
			});

			const modalGroups = ref(null);
			const groups = computed(() => {
				if(!hasUser.value) return [];
				else return _.orderBy(user.value.groups, ['name']);
			});

			const modalSearches = ref(null);
			const searches = computed(() => {
				let list = store.getters['auth/searches'];
				if(props.microsite?.id) list = _.filter(list, { microsite_id: props.microsite?.id ?? null });
				else if(props.user?.id) list = _.filter(list, { user_id: props.user?.id ?? null });
				else if(props.group?.id) list = _.filter(list, { group_id: props.group?.id ?? null });

				return _.take(_.orderBy(list, ['created_at'], ['desc']), 5);
			});

			const canSearch = computed(() => {
				if(search.value.filters.length > 0 || form.value?.completed) return true;
				else return false;
			});

			const stepsGeneral = computed(() => {
				let steps = [];
				if(_.some(search.value, { type: 'property' }) || form.value?.form.type == 'property') steps.push('places');

				return steps;
			});

			const groupAdd = () => {
				if(form.value?.completed) {
					let data = form.value?.getData();
					search.value.filters.push(data);
					toast.success(`Búsqueda de ${data.title} agregada correctamente.`, { timeout: 7000 });
					form.value?.empty();
					VueScrollTo.scrollTo(document.body, 500);
				}
			}

			const groupRemove = (index) => {
				search.value.filters.splice(index, 1);
			}

			const submit = async () => {
				submitting.value = true;

				let send = _.cloneDeep(search.value);
				if(form.value?.completed) send.filters.push(form.value?.getData());

				if(send.filters.length == 1) send.title = _.head(send.filters).title;
				else send.title = `Búsqueda con ${send.filters.length} filtros`;

				await SearchRepository.store(send).then((response) => {
					store.dispatch('auth/upsertSearch', response.data);
					router.push({ name: 'search.result', params: { uuid: response.data.uuid }});
				}).catch((e) => {
					catchError(e, true);
				});

				submitting.value = false;
			}

			return { modalGroups, groups, modalSearches, searches, form, search, submitting, canSearch, stepsGeneral, groupAdd, groupRemove, submit };
		}
	}
</script>