<template>
	<div>
		<div class="hstack gap-2 justify-content-between align-items-center position-relative">
			<MicrositeCardSubtitle :microsite="microsite" subtitle="Buscando en todo el sitio" class="pb-2 text-limit" v-if="!user_id" />
			<UserCardSubtitle :user="user" subtitle="Buscando en la cuenta" class="pb-2 text-limit" v-else />
			<a href="javascript:void(0);" class="btn btn-sm btn-neutral rounded-pill stretched-link" @click="modalSelect.show();" v-if="microsite.users.length >= 2">Cambiar</a>
		</div>

		<MicrositeModalSelect ref="modalSelect" :microsite="microsite" :users="microsite.users" v-if="microsite.users.length > 0" @select="select" />
	</div>
</template>

<script>
	import { ref, computed } from 'vue';
	import _ from 'lodash';

	export default {
		props: {
			microsite: Object,
			user_id: Number
		},
		setup(props, { emit }) {
			const modalSelect = ref(null);
			const user = computed(() => _.find(props.microsite.users, { id: props.user_id }));
			const select = (id) => emit('update:user_id', id);

			return { modalSelect, user, select };
		}
	}
</script>