<template>
	<MarkerCluster :options="{ renderer: renderer }">
		<slot />
	</MarkerCluster>
</template>

<script>
	import { MarkerCluster } from 'vue3-google-map';
	import assetsImgIconsClusterFill from '@/assets/img/icons/cluster-fill.png';

	export default {
		components: { MarkerCluster },
		setup() {
			const renderer = {
				render: ({ count, position }) =>
					new google.maps.Marker({
						position,
						icon: {
							url: assetsImgIconsClusterFill,
							scaledSize: {
								width: 50,
								height: 50
							},
							anchor: new google.maps.Point(25, 25)
						},
						// adjust zIndex to be above other markers
						zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
						gridSize: 10,
					}),
			}

			return { renderer };
		}
	}
</script>