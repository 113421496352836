<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Ubicación en mapa" :subtitle="(loaded) ? `${markers.length} publicaciones encontradas` : 'Cargando publicaciones'" headerClass="container-md pt-4 pb-3" bodyClass="container-fluid vstack gap-5 p-0" id="search-modal-map">
		<template #body>
			<CommonMapMap ref="mapRef" mapStyle="height: 100vh; width: 100%;" mapClass="" :zoom="13" :minZoom="13" @onIdle="onIdle">
				<CommonMapCluster>
					<CommonMapMarkerPrice v-for="marker in markersDisplay" :key="marker.id" :position="{ lat: marker.address.geolocation.coordinates[0], lng: marker.address.geolocation.coordinates[1] }" :currency="marker.currency" :amount="marker.amount_sale ?? marker.amount" :priority="marker.priority" :id="marker.id" :vieweds="vieweds" @click="display(marker.id)" />
				</CommonMapCluster>
			</CommonMapMap>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref, watch, onMounted } from 'vue';
	import _ from 'lodash';
	import SearchRepository from '@/repositories/SearchRepository';

	export default {
		emits: ['display'],
		props: {
			search: Object,
			vieweds: {
				type: Array,
				default: []
			}
		},
		setup(props, { emit }) {
			const offcanvas = ref(null);
			const mapRef = ref(null);
			const loaded = ref(false);
			const default_id = ref(null);
			const maxMarkers = 20;
			const maxMarkersZoom = 16;

			const bounds = ref(null);
			const markers = ref([]);
			const markersDisplay = ref([]);

			const show = (id = null) => {
				default_id.value = id;
				offcanvas.value.offcanvas.show();
			}

			const hide = () => {
				offcanvas.value.offcanvas.hide();
			}

			let url = new URL(window.location.origin);
				url.pathname = '/img/icons/cluster';
				url.port = '';
			const baseClusterImage = url.toString();

			const getMarkers = () => {
				SearchRepository.markers(props.search.uuid).then((response) => {
					loaded.value = true;
					markers.value = response.data;
				});
			}

			const onIdle = () => {
				let bounds = mapRef.value.mapRef.map.getBounds(),
					zoom = mapRef.value.mapRef.map.getZoom(),
					markersInBounds = [];

				if(bounds) {
					const visibleMarkersList = _.filter(markers.value, (marker) => {
						return bounds.contains(new google.maps.LatLng(marker.address.geolocation.coordinates[0], marker.address.geolocation.coordinates[1]));
					});

					if(zoom >= maxMarkersZoom) {
						markersInBounds = visibleMarkersList;
					}else{
						const gridSize = Math.ceil(Math.sqrt(maxMarkers)); // Número de celdas por fila/columna
						const latStep = (bounds.getNorthEast().lat() - bounds.getSouthWest().lat()) / gridSize;
						const lngStep = (bounds.getNorthEast().lng() - bounds.getSouthWest().lng()) / gridSize;

						const grid = Array.from({ length: gridSize }, () => Array(gridSize).fill(null));

						visibleMarkersList.forEach((marker) => {
							const latIndex = Math.floor((marker.address.geolocation.coordinates[0] - bounds.getSouthWest().lat()) / latStep);
							const lngIndex = Math.floor((marker.address.geolocation.coordinates[1] - bounds.getSouthWest().lng()) / lngStep);
							if(!grid[latIndex][lngIndex]) grid[latIndex][lngIndex] = marker;
						});

						markersInBounds = _.compact(_.flatten(grid));
					}
				}

				markersDisplay.value = markersInBounds;
			}

			const display = (id) => {
				emit('display', id);
			}

			onMounted(() => {
				document.getElementById('search-modal-map').addEventListener('show.bs.offcanvas', () => {
					if(!loaded.value) getMarkers();
				});
			});

			watch([() => mapRef.value?.mapRef.ready, markers], ([newReady, newMarkers]) => {
				if(newReady && (newMarkers ?? []).length > 0) {
					let markerDefault = null;

					if(default_id.value) markerDefault = _.find(newMarkers, { id: default_id.value });
					if(!markerDefault) markerDefault = _.head(newMarkers);

					if(markerDefault) {
						mapRef.value.mapRef.map.setCenter({ lat: markerDefault.address.geolocation.coordinates[0], lng: markerDefault.address.geolocation.coordinates[1] });
						//mapRef.value.mapRef.map.setZoom(16);
					}
				}
			});

			return { baseClusterImage, offcanvas, loaded, show, hide, mapRef, markers, markersDisplay, onIdle, display };
		}
	}
</script>