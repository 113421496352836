<template>
	<transition name="block" mode="out-in">
		<div class="vstack gap-1 h-100 justify-content-center position-relative mx-auto text-center align-items-center lh-xs" v-if="show">
			<img :src="logo" class="h-rem-8 logo" alt="logo">
			<div class="text-muted" v-if="text">{{ text }}</div>
		</div>
	</transition>
</template>

<script>
	import { ref, computed, onMounted, inject } from 'vue';
	import assetsImgLogoComplete from '@/assets/img/logo/complete.png';

	export default {
		props: {
			text: String
		},
		setup(props) {
			const $env = inject('$env');
			const show = ref(false);
			const logo = computed(() => {
				if($env?.MICROSITE?.logo_secondary_url) return $env.MICROSITE.logo_secondary_url;
				else return assetsImgLogoComplete;
			});

			onMounted(() => {
				show.value = true;
			});

			return { logo, show };
		}
	}
</script>

<style scoped>
	.logo {
		display: block;
		width: auto;
		height: auto;
	}

	.block-enter-from { opacity: 0; }
	.block-enter-to { opacity: 1; }

	.block-enter-active, .block-leave-active { transition: all 3s ease; }
</style>