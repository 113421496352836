<template>
	<LayoutClean class="vstack mh-100 h-100">
		<LayoutHeaderMain :main="true" :fixed="true" shadow="ever" :style="microsite.style?.header" />
		<div class="container-md mh-100 h-100 overflow-hidden">
			<MicrositeIndexImagesGrid :images="microsite.images" />
			<MicrositeIndexDescription :microsite="microsite" />
		</div>
	</LayoutClean>
</template>

<script>
	import { onMounted, onUnmounted } from 'vue';

	export default {
		name: 'ViewsMicrositeHome',
		props: {
			microsite: Object
		},
		setup(props) {
			const themeColor = document.querySelector('meta[name="theme-color"]').content;

			onMounted(async () => {
				document.querySelector('meta[name="theme-color"]').content = props.microsite.style?.body?.style?.backgroundColor;
				document.body.style.backgroundColor = props.microsite.style?.body?.style?.backgroundColor;
			});

			onUnmounted(() => {
				document.querySelector('meta[name="theme-color"]').content = themeColor;
				document.body.style.backgroundColor = '';
			});
		}
	}
</script>