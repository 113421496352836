<template>
	<LayoutClean class="vstack justify-content-center bg-primary">
		<div class="container-sm my-4">
			<div class="vstack gap-5">
				<div class="hstack align-items-center justify-content-between">
					<!-- Logo -->
					<router-link :to="{ name: 'home' }">
						<img src="@/assets/img/logo/complete-white.png" class="h-rem-10" alt="logo">
					</router-link>
					<div>
						<UserCardMenu :user="user" v-if="hasUser" />
						<UserCardMenuEmpty v-else />
					</div>
				</div>
				<div class="vstack gap-3">
					<template v-if="hasUser && (user.slug || user.groups.length > 0 || accounts.length > 0)">
						<HomeGroup :groups="user.groups" v-if="user.groups.length > 0" />
						<UserCardHome :user="user" v-if="user.slug" />
						<HomeAccount :accounts="accounts" v-if="accounts.length > 0" />
					</template>
					<div class="card text-bg-white border-0 rounded-4 shadow-none">
						<div class="card-body vstack gap-2 p-4 text-center text-md">
							<div class="ls-tight lh-xs">Si te gusta nuestra plataforma o tienes alguna duda ¡no dudes en contactarnos!</div>
							<a :href="`mailto:${$env.CONTACT_EMAIL}`" class="link-primary fw-bold">{{ $env.CONTACT_EMAIL }}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</LayoutClean>
</template>

<script>
	import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
	import store from '@/store';
	import composableForm from '@/composables/form';
	import AuthRepository from '@/repositories/AuthRepository';

	export default {
		setup() {
			const { catchError } = composableForm();
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const user = computed(() => store.getters['auth/user']);
			const themeColor = document.querySelector('meta[name="theme-color"]').content;
			const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--x-primary');
			const accounts = ref([]);

			const getAccounts = () => {
				if(hasUser.value) {
					AuthRepository.accounts().then((response) => {
						accounts.value = response.data;
					}).catch((e) => catchError(e, true));
				}
			}

			onMounted(() => {
				document.querySelector('meta[name="theme-color"]').content = primaryColor;
				getAccounts();
			});

			onUnmounted(() => {
				document.querySelector('meta[name="theme-color"]').content = themeColor;
			});

			watch(hasUser, getAccounts);

			return { hasUser, user, accounts };
		}
	}
</script>