<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="¿Dónde deseas buscar?" id="microsite-modal-select" bodyClass="container-md vstack gap-5 pt-2">
		<template #body>
			<div class="vstack gap-5">
				<div class="list-group list-group-flush">
					<div class="list-group-item vstack px-0">
						<MicrositeCardSelect :microsite="microsite" subtitle="Buscar en todo el sitio" @select="select(null);" />
					</div>
					<div class="list-group-item vstack px-0" v-for="user in users" :key="user.id">
						<UserCardSelect :user="user" subtitle="Buscar en esta cuenta" @select="select(user.id);" />
					</div>
				</div>
			</div>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref } from 'vue';

	export default {
		emits: ['select'],
		props: {
			microsite: Object,
			users: Array
		},
		setup(props, { emit }) {
			const offcanvas = ref(null);
			const show = () => offcanvas.value.offcanvas.show();
			const select = (id) => {
				emit('select', id);
				offcanvas.value.offcanvas.hide();
			}

			return { offcanvas, show, select };
		}
	}
</script>