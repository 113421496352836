<template>
	<SearchFilter :microsite="microsite" :user="user" :prefilters="microsite?.filters" />
</template>

<script>
	import _ from 'lodash';

	export default {
		props: {
			microsite: Object
		},
		setup(props) {
			const user = _.find(props.microsite.users, { pivot: { default: true }});

			return { user };
		}
	}
</script>