import store from '@/store';
import _ from 'lodash';
import composableForm from '@/composables/form';
import ConfigurationRepository from '@/repositories/ConfigurationRepository';

const setConfiguration = (app, config) => {
	app.config.globalProperties.$env = config;
	app.provide('$env', config);
}

const setPageInfo = (config) => {
	let icon = (config?.MICROSITE) ? config.MICROSITE.logo_square_url : '/img/logo/favicon.png',
		title = (config?.MICROSITE) ? config.MICROSITE.name : import.meta.env.VITE_APP_NAME;

	let link = document.createElement('link');
	link.rel = 'icon';
	link.href = icon;

	document.head.appendChild(link);
	document.title = title;
}

export const loadConfiguration = async (app) => {
	const { catchError } = composableForm();
	const info = store.getters['config/info'];

	if(info) {
		setConfiguration(app, info);
		setPageInfo(info);
	}else await ConfigurationRepository.index().then((response) => {
		store.dispatch('config/setInfo', response.data);

		setConfiguration(app, response.data);
		setPageInfo(response.data);
	}).catch(catchError);
};