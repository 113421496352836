<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Seleccionar fuente" id="account-stat-modal-option">
		<template #body>
			<div class="list-group list-group-flush gap-5">
				<div class="list-group-item hstack gap-5 border-0 p-0" v-for="(source, index) in sources" v-bind:key="index">
					<div>
						<a href="javascript:void(0)" class="d-block h5 fw-bold stretched-link" @click="select(source);">{{ source.entity.name }}</a>
						<p class="text-sm lh-xs" v-if="source.type == 'App\\Models\\User'">Estadísticas de la cuenta</p>
						<p class="text-sm lh-xs" v-else-if="source.type == 'App\\Models\\Microsite'">Estadísticas del sitio</p>
						<p class="text-sm lh-xs" v-else-if="source.type == 'App\\Models\\Group'">Estadísticas del grupo</p>
					</div>
					<div class="ms-auto text-end align-self-center">
						<i class="bi bi-chevron-right"></i>
					</div>
				</div>
			</div>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref } from 'vue';

	export default {
		emits: ['select'],
		props: {
			sources: Array
		},
		setup(props, { emit }) {
			const offcanvas = ref(null);
			const show = () => offcanvas.value.offcanvas.show();
			const select = (source) => {
				emit('select', source);
				offcanvas.value.offcanvas.hide();
			}

			return { offcanvas, show, select };
		}
	}
</script>