<template>
	<a href="javascript:void(0);" class="link-dark hstack gap-3 align-items-center" @click="select">
		<UserCommonImage :src="user?.avatar_url" size="md" rounded="circle" :border="true" class="mx-auto" />
		<div class="flex-fill lh-sm text-limit">
			<div class="text-md text-truncate fw-bold">{{ user?.name }}</div>
			<div class="text-sm text-truncate text-muted hstack gap-1" v-if="subtitle">{{ subtitle }}</div>
		</div>
	</a>
</template>

<script>
	export default {
		props: {
			user: Object,
			subtitle: {
				type: String,
				default: null
			}
		},
		setup(props, { emit }) {
			const select = () => emit('select', props.user.id);

			return { select };
		}
	}
</script>