<template>
	<router-link :to="{ name: 'home' }" class="hstack gap-2 align-items-center">
		<MicrositeCommonImage :src="microsite.logo_square_url" size="md" v-if="microsite?.logo_square_url" />
		<div class="flex-fill lh-xs text-limit">
			<div class="h3 text-break text-truncate">{{ microsite.name }}</div>
			<div class="text-muted text-truncate" v-if="subtitle">{{ subtitle }}</div>
		</div>
	</router-link>
</template>

<script>
	export default {
		props: {
			microsite: Object,
			subtitle: {
				type: String,
				default: null
			}
		}
	}
</script>