<template>
	<footer class="sticky-bottom vstack gap-4 align-items-center text-center background-filter z-2 pt-6 pb-7">
		<UserCardHeader :user="user" />
		<router-link :to="{ name: 'user.search.index', params: { slug: user.slug }}" class="btn btn-md btn-outline-dark border-2 fw-bold px-10 text-uppercase rounded-pill">Buscar</router-link>
	</footer>
</template>

<script>
	export default {
		props: {
			user: Object
		}
	}
</script>

<style scoped>
	.background-filter {
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(255, 255, 255, 1) 4.5rem);
	}
</style>