<template>
	<GoogleMap
		ref="mapRef"
		:api-key="$env.GOOGLE_API_KEY"
		:mapId="$env.GOOGLE_MAPS_MAP_ID"
		:zoom="zoom"
		:minZoom="minZoom"
		:center="center"
		:disableDefaultUi="true"
		:style="mapStyle"
		:class="mapClass"
		mapTypeId="roadmap"
		@idle="onIdle">
		<slot />
	</GoogleMap>
</template>

<script>
	import { ref } from 'vue';
	import { GoogleMap } from 'vue3-google-map';

	export default {
		components: { GoogleMap },
		emits: ['onIdle'],
		props: {
			center: Object,
			zoom: {
				type: Number,
				default: 16
			},
			minZoom: Number,
			mapStyle: {
				type: String,
				default: 'height: 250px; width: 100%;'
			},
			mapClass: {
				type: String,
				default: 'border border-2 border-dark rounded-4 overflow-hidden mt-2'
			}
		},
		setup(props, { emit }) {
			const mapRef = ref(null);

			const reset = () => {
				mapRef.value.map.setZoom(16);
				mapRef.value.map.setCenter(props.center);
			}

			const onIdle = () => emit('onIdle');

			return { mapRef, reset, onIdle };
		}
	}
</script>