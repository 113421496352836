<template>
	<LayoutOffcanvasStandard ref="offcanvas" id="user-modal-contact" :title="user?.name" subtitle="Medios de contacto habilitados" bodyClass="container-md vstack gap-5 py-2">
		<template #body>
			<div class="vstack gap-5">
				<ul class="list-group list-group-flush rounded-4" v-if="user?.id">
					<template v-if="user.contact_types">
						<li class="list-group-item px-0 py-3 lh-sm hstack gap-1" v-if="user.contact_types.includes('email') && user.email">
							<div class="vstack">
								<a :href="`mailto:${user.email}`" class="fw-bold link-dark stretched-link">Contactar por email</a>
								<small class="d-block fw-normal">{{ user.email }}</small>
							</div>
							<i class="bi bi-envelope-at text-xl"></i>
						</li>
						<li class="list-group-item px-0 py-3 lh-sm hstack gap-1" v-if="user.contact_types.includes('phone') && user.phone">
							<div class="vstack">
								<a :href="`tel:${phoneFormatted.number}`" class="fw-bold link-dark stretched-link">Llamar por teléfono</a>
								<small class="d-block fw-normal">{{ phoneFormatted.nationalNumber }}</small>
							</div>
							<i class="bi bi-telephone-plus text-xl"></i>
						</li>
						<div class="list-group-item px-0 py-3 lh-sm hstack gap-1" v-if="user.contact_types.includes('whatsapp') && user.phone">
							<div class="vstack">
								<a :href="`https://wa.me/${phoneFormatted.number}`" target="_blank" class="fw-bold link-dark stretched-link">Contactar por Whatsapp</a>
								<small class="d-block fw-normal">{{ phoneFormatted.nationalNumber }}</small>
							</div>
							<i class="bi bi-whatsapp text-xl"></i>
						</div>
					</template>
					<template v-else>
						<li class="list-group-item px-0 py-3 lh-sm">
							No se encontraron datos de contacto.
						</li>
					</template>
				</ul>
			</div>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref, computed, onMounted, getCurrentInstance } from 'vue';
	import { parsePhoneNumber } from 'libphonenumber-js';
	import { Modal, Offcanvas } from 'bootstrap';

	export default {
		setup() {
			const instance = getCurrentInstance();
			const offcanvas = ref(null);
			const user = ref(null);
			const phoneFormatted = computed(() => {
				return (user.value?.phone) ? parsePhoneNumber(user.value.phone) : null
			});

			const closeOpenOffcanvas = () => {
				if(document.querySelector('.offcanvas.show')) {
					var offcanvasShowed = Offcanvas.getInstance(document.querySelector('.offcanvas.show'));
					offcanvasShowed.hide();
				}

				if(document.querySelector('.modal.show')) {
					var modalShowed = Modal.getInstance(document.querySelector('.modal.show'));
					modalShowed.hide();
				}
			}

			const contactUser = (data = null) => {
				closeOpenOffcanvas();
				user.value = data;
				offcanvas.value.offcanvas.show();
			}

			onMounted(async () => {
				document.getElementById('user-modal-contact').addEventListener('hidden.bs.offcanvas', () => user.value = null);
				if(instance) instance.appContext.config.globalProperties.$contactUser = contactUser;
			});

			return { user, offcanvas, phoneFormatted };
		}
	}
</script>