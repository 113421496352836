<template>
	<LayoutTableCommonRow>
		<LayoutTableCommonPartText :text="search.title" :header="dateFormatted">
			<template #extras>
				<div class="text-sm text-muted hstack gap-1">
					<div class="text-dark" v-if="search?.microsite && !search?.user">En toda la aplicación <b>{{ search.microsite.name }}</b></div>
					<div class="text-dark" v-else-if="search?.microsite && search?.user">En <b>{{ search.user.name }}</b>, desde <b>{{ search.microsite.name }}</b></div>
					<div class="text-dark" v-else-if="search?.user">En <b>{{ search.user.name }}</b></div>
					<div class="text-dark" v-else-if="search?.group">En <b>{{ search.group.name }}</b></div>
					<div class="text-dark" v-else>En <b>toda la plataforma</b></div>
				</div>
			</template>
		</LayoutTableCommonPartText>
		<LayoutTableCommonPartRaw class="text-end">
			<div class="vstack gap-1">
				<div class="hstack justify-content-end text-success lh-1" v-tooltip:left="`Me gusta`">{{ search.decisions_true_count }}<i class="bi bi-heart-fill ms-1"></i></div>
				<div class="hstack justify-content-end text-danger lh-1" v-tooltip:left="`No me gusta`">{{ search.decisions_false_count }}<i class="bi bi-x-lg ms-1"></i></div>
			</div>
		</LayoutTableCommonPartRaw>
		<LayoutTableCommonPartRaw class="text-end">
			<div class="hstack justify-content-end avatar-group">
				<UserAvatarRounded v-for="user in search.users" :key="user.id" :user="user" size="xs" :border="((user.pivot.owner) ? 'success' : 'warning')" v-tooltip:left="`${user.name} (${((user.pivot.owner) ? 'Creador' : 'Invitado')})`" />
			</div>
		</LayoutTableCommonPartRaw>
	</LayoutTableCommonRow>
</template>

<script>
	import { computed } from 'vue';
	import moment from 'moment';

	export default {
		props: {
			search: Object
		},
		setup(props) {
			const dateFormatted = computed(() => moment(props.search.created_at).format('DD/MM/YYYY HH:mm'));

			return { dateFormatted };
		}
	}
</script>