import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import auth from './modules/auth';
import config from './modules/config';
import microsite from './modules/microsite';
import redirect from './modules/redirect';
import user from './modules/user';

const vuexLocalStorage = new VuexPersistence({
	storage: window.localStorage,
	modules: ['auth']
});

const vuexSessionStorage = new VuexPersistence({
	storage: window.sessionStorage,
	modules: ['config', 'microsite', 'redirect', 'user']
});

export default createStore({
	plugins: [
		vuexLocalStorage.plugin,
		vuexSessionStorage.plugin
	],
	modules: {
		auth: auth,
		config: config,
		microsite: microsite,
		redirect: redirect,
		user: user
	}
});