<template>
	<router-link :to="{ name: 'home' }" class="vstack gap-4 text-center text-reset">
		<MicrositeCommonImage :src="microsite.logo_square_url" :size="imageSize" class="mx-auto" imageClass="border border-2 border-dark bg-dark" :style="style" v-if="microsite?.logo_square_url" />
		<div class="text-break text-reset lh-1" :class="nameClass" :style="{ marginTop: (!microsite?.logo_square_url) ? '70px' : 0 }">{{ microsite.name }}</div>
	</router-link>
</template>

<script>
	export default {
		props: {
			microsite: Object,
			style: Object,
			nameClass: {
				type: String,
				default: 'h2 px-5'
			},
			imageSize: {
				type: String,
				default: 'xl'
			}
		}
	}
</script>