<template>
	<router-view v-slot="{ Component }">
		<transition name="route" mode="out-in">
			<keep-alive :include="['ViewsMicrositeHome', 'ViewsUserHome', 'ViewsGroupHome']" :max="5">
				<component :key="$route.fullPath" :is="Component" />
			</keep-alive>
		</transition>
	</router-view>
	<AppPermissionInstall />
	<AppPermissionNotification />
	<AuthModalIdentify />
	<UserModalContact />
</template>

<style>
	.route-enter-from { opacity: 0; }
	.route-enter-to { opacity: 1; }

	.route-leave-from { opacity: 1; }
	.route-leave-to { opacity: 0; }

	.route-enter-active, .route-leave-active { transition: all .2s ease; }
</style>