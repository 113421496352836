<template>
	<CustomMarker :options="options" @click="click">
		<span class="badge rounded-pill px-2 py-1 text-sm lh-1 border border-2 shadow bg-white" :class="spanClass"><i class="bi pe-1" :class="`bi-${info.style.icon}`" v-if="info?.style?.icon"></i>{{ text }}</span>
	</CustomMarker>
</template>

<script>
	import { computed, inject } from 'vue';
	import { CustomMarker } from 'vue3-google-map';
	import assetsImgIconsMarkerPrimary from '@/assets/img/icons/marker-primary.png';

	export default {
		components: { CustomMarker },
		emits: ['click'],
		props: {
			position: Object,
			currency: String,
			amount: Number,
			priority: Number,
			id: Number,
			vieweds: {
				type: Array,
				default: []
			}
		},
		setup(props, { emit }) {
			const $env = inject('$env');
			const viewed = computed(() => props.vieweds.includes(props.id));
			const info = computed(() => (props.priority in $env.MODEL.publication.priorities) ? $env.MODEL.publication.priorities[props.priority] : null);

			const spanClass = computed(() => {
				if(viewed.value) return `text-muted ${(info.value?.style?.icon) ? 'ps-1' : ''}`;
				else if(info.value) return `border-${info.value.style.color} text-${info.value.style.color} ${(info.value?.style?.icon) ? 'ps-1' : ''}`;
				else return 'border-dark text-dark';
			});

			const options = computed(() => {
				return {
					position: props.position,
					anchorPoint: 'CENTER',
					collisionBehavior: 'REQUIRED'
				};
			});

			const text = computed(() => {
				if(!props.amount) return 'Sin precio';
				else if(props.amount >= 1000000) return `${(props.amount / 1000000).toFixed(1)}M ${props.currency}`;
				else if (props.amount >= 10000) return `${Math.floor(props.amount / 1000)}k ${props.currency}`;
				else if (props.amount >= 1000) return `${(props.amount / 1000).toFixed(1)}k ${props.currency}`;
				else return `${Math.floor(props.amount)} ${props.currency}`;
			});

			const click = () => emit('click');

			return { spanClass, info, options, text, click };
		}
	}
</script>